<template>
  <div class="d-flex flex-column justify-center" style="width: 100%">
    <v-container
      class="d-flex flex-column"
      style="max-width: 1030px; margin-top: 50px"
    >
      <h2>Maksutavat</h2>
      <p>
        Verkkokaupassamme voit maksaa ostoksesi Klarna Checkout -palvelun
        avulla:
      </p>
      <p>
        Käytössäsi olevat vaihtoehdot ovat Lasku, erämaksu, verkkopankit ja
        luottokortit. Käyttämällä Klarna Checkout -palvelua hyväksyt Klarna Bank
        AB:n (556737-0431, Sveavägen 46, 11134 Stockholm) ehdot, jotka löydät
        kassalta. Mikäli sinulla on maksamiseen liittyviä kysymyksiä, pyydämme
        ottamaan suoraan yhteyttä Klarnan asiakaspalveluun. Löydät
        asiakaspalvelun yhteystiedot, Oma Klarna -palvelun ja muuta hyödyllistä
        tietoa osoitteesta <a href="https://www.klarna.com/fi/asiakaspalvelu" target="_blank">https://www.klarna.com/fi/asiakaspalvelu</a>
      </p>
      <h2>Toimitustavat ja -maksut</h2>
      <p>
        Kustannustehokkuuden ja ekologisuuden vuoksi toimitamme
        verkkokauppatilaukset aina pääsääntöisesti pahvilaatikoissa, joissa itse
        vastaanotamme tuotteet tavarantoimittajilta. Tilausten toimittamiseen
        käytämme Matkahuoltoa. Toimituksen saapumisesta lähimpään
        paketinnoutopisteeseen ilmoitetaan aina saapumisilmoituksella, jonka
        jälkeen paketti on heti noudettavissa. Toimituksiin lisäämme käsittely-
        ja postituskulut 7,90-45,00 euroa. Lisäksi särkyviin ja erilliskäsittelyä
        vaativiin tuotteisiin lisätään särkyvälisä 7,10 euroa. Palautuksen
        tapahtuessa emme palauta toimituskuluja.
      </p>
      <p>
        Suuremmat tuotteet, kuten huonekalut, suuret peilit, valaisimet sekä
        muut erityiskäsittelyä vaativat tuotteet lähetämme Matkahuollon kautta
        erityiskuljetuksena. Tuotetiedoissa on maininta, mikäli kyseinen tuote
        vaatii erityiskuljetuksen. Halutessasi tilata tuotteen, otathan yhteyttä
        myymälään kuljetuksen järjestämiseksi. Tilauksesi voit myös noutaa
        myymälästä ilman ylimääräisiä kuluja.
      </p>
      <p>
        Mikäli tilauksessasi on jotakin epäselvää (esim. väärin valittu
        toimitustapa, loppunut tuote tms.), olemme aina ensisijaisesti
        yhteydessä asiakkaaseen sähköpostitse. Mikäli emme saa asiakkaaseen
        yhteyttä kahden viikon sisällä tilauksen teosta, tilaus peruuntuu
        automaattisesti.
      </p>
      <h2>Toimitusaika</h2>
      <p>
        Varastossa olevat tuotteet pyrimme toimittamaan Matkahuollon
        toimitettavaksi 3 arkipäivän kuluessa tilauksen vastaanottamisesta.
        Pyrimme aina pitämään verkkokaupan tuotevalikoiman ajan tasalla. Mikäli
        jokin tilaamistasi tuotteista on kuitenkin päässyt loppumaan, ilmoitamme
        tästä välittömästi ja palautamme tuotteen ostosta aiheutuneen
        maksusuorituksen asiakkaan käyttämälle maksutavalle. Vaihtoehtoisesti
        asiakas voi jäädä odottamaan puuttuvan tuotteen saapumista varastoon,
        jonka jälkeen toimitamme tehdyn tilauksen yhtenä kokonaisuutena.
      </p>
      <p>
        Tilaustuotteiden kohdalla toimitusaika on merkitty erikseen. Pyrimme
        toimittamaan tilaukset yhtenä kokonaisuutena, jolloin tilaustuotteelle
        määritelty toimitusaika pätee koko tilaukseen jollei toisin sovita.
      </p>
      <p>
        Myymälästä noudettavia ja sinne maksettavia tuotteita pidämme varattuna
        myymälässä seitsemän vuorokauden ajan.
      </p>
      <h2>Tuotteen palautus, reklamointi ja vaihto</h2>
      <p>
        Tuotteen palautuksesta on aina ilmoitettava erikseen sähköpostitse
        osoitteeseen <a href="mailto:myymala@loviisanaitta.fi">myymala@loviisanaitta.fi</a> ennen tuotteen palautusta.
        Huomioithan, että emme hyväksy palautuksia, joista ei ole ilmoitettu
        meille ennakkoon. Pelkkä tavaran palauttaminen tai noutamatta jättäminen
        postista ilman erillistä peruuttamista/palauttamista koskevaa ilmoitusta
        ei riitä.
      </p>
      <p>
        Tuotteiden palautusoikeus on 14 vrk ostohetkestä. Palautettavan tuotteen
        on oltava täysin käyttämätön ja myyntikelpoinen sekä pakattuna
        alkuperäiseen pakkaukseensa. Myös alkuperäiset hintalaput ja mahdolliset
        tuoteselosteet tulee olla tuotteessa kiinni.
        <br />
        Myymälästä noudettujen tuotteiden palautus on suoraan myymälään.
        <br />
        Uusiin EU-määräyksiin vedoten asiakaspalautuksen kulut jäävät asiakkaan
        maksettaviksi. Huomioithan, että palautusoikeus ei koske määrämittaan
        leikattuja kankaita, elintarvikkeita, tilaustuotteita eikä
        sinettipusseista poistettuja tuotteita, ellei niissä ole jotakin vikaa.
      </p>
      <p>
        Halutessasi vaihtaa tuotteen toiseen, tee normaalisti asiakaspalautus ja
        sen jälkeen verkkokaupan kautta uusi tilaus.
      </p>
      <p>
        Kaikkien tuotteidemme kunto tarkistetaan ennen niiden toimittamista
        asiakkaalle. Mikäli kuitenkin havaitset tuotteessa vian, ilmoitathan
        siitä sähköpostitse osoitteeseen <a href="mailto:myymala@loviisanaitta.fi">myymala@loviisanaitta.fi</a> viimeistään
        kaksi vuorokautta lähetyksen vastaanottamisesta. Pyydämme myös
        toimittamaan viallisesta tuotteesta selkeän valokuvan sähköpostin
        liitteenä. Mikäli havaitset postipakettia vastaanottaessasi sen olevan
        vaurioitunut kuljetuksessa, tee asiasta heti paketin noutovaiheessa
        vahinkoilmoitus postiin.
      </p>
      <p>
        Huom. Viiden (5) tuotepalautuksen jälkeen emme valitettavasti voi enää
        toimittaa lähetyksiä.
      </p>
    </v-container>
    <foo-ter />
  </div>
</template>

<script>
import template from "../data/aboutus.json";
export default {
  data() {
    return {
      contentTemplate: template.template.content,
    };
  },
  created: function () {
    if (template.template.data) {
      this.$store.commit("updateCMSData", template.template.data);
    }
  },
};
</script>

<style scoped>
h2,
h3 {
  font-family: "Lora", serif;
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .life-style-content,
  .family {
    flex-direction: column;
    align-items: center;
  }

  .collection {
    flex-direction: column-reverse;
    align-items: center;
  }

  .life-style-content .v-image {
    margin: 0 auto 32px 0 !important;
  }

  .container .v-image {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>